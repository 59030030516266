/*css all */

body {
    height: '100%';
}

/* FONTS */
.dol-f-nunito {
    font-family: "Nunito", sans-serif;
}
.dol-f-nunito-italic {
    font-family: "Nunito Italic", sans-serif;
}

.dol-f-nunito-light {
    font-family: "Nunito Light", sans-serif;
}

.dol-f-nunito-extra-light {
    font-family: "Nunito Extra Light", sans-serif !important;
}

.dol-f-nunito-semi-bold {
    font-family: "Nunito Semi Bold", sans-serif;
}

.dol-f-nunito-bold {
    font-family: "Nunito Bold", sans-serif;
}

.dol-f-nunito-extra-bold {
    font-family: "Nunito Extra Bold", sans-serif;
}

.dol-f-nunito-black {
    font-family: "Nunito Black", sans-serif;
}

/* SIZE */

.dol-f-10 {
    font-size: 10px;
}

.dol-f-12 {
    font-size: 12px;
}

.dol-f-13 {
    font-size: 13px;
}

.dol-f-14 {
    font-size: 14px;
}

.dol-f-15 {
    font-size: 15px;
}

.dol-f-16 {
    font-size: 16px;
}

.dol-f-18 {
    font-size: 18px;
}

.dol-f-20 {
    font-size: 20px;
}

.dol-f-22 {
    font-size: 22px;
}

.dol-f-24 {
    font-size: 24px;
}

.dol-f-26 {
    font-size: 26px;
}

.dol-f-28 {
    font-size: 28px;
}

.dol-f-32 {
    font-size: 32px;
}

.dol-f-30 {
    font-size: 30px;
}

.dol-f-34 {
    font-size: 34px;
}

.dol-f-36 {
    font-size: 36px;
}

.dol-f-38 {
    font-size: 38px;
}

.dol-f-42 {
    font-size: 42px;
}

.dol-f-w-700 {
    font-weight: 700 !important;
}

.dol-f-w-900 {
    font-weight: 900 !important;
}

.dol-f-52 {
    font-size: 52px !important;
}

.dol-f-65 {
    font-size: 65px !important;
}

.dol-f-w-700 {
    font-weight: 700 !important;
}

.dol-f-lh-20 {
    line-height: 20px;
}

.dol-f-lh-22 {
    line-height: 22px;
}

.dol-f-lh-28 {
    line-height: 28px;
}

.dol-f-lh-50 {
    line-height: 50px;
}

.dol-f-lh-26 {
    line-height: 26px;
}

.dol-f-lh-34 {
    line-height: 34px;
}

.dol-f-lh-36 {
    line-height: 36px;
}

.dol-f-lh-40 {
    line-height: 40px;
}

/* WIDTH */
.dol-w-40 {
    width: 40px;
}

.dol-w-50 {
    width: 50px;
}

.dol-w-70 {
    width: 70px !important;
}

.dol-w-80 {
    width: 80px !important;
}

.dol-w-90 {
    width: 90px;
}

.dol-w-130 {
    width: 130px;
}
.dol-w-200 {
    width: 200px;
}

.dol-w-245 {
    width: 245px;
}

.dol-w-355 {
    width: 355px;
}

.dol-w-470 {
    width: 470px;
}

.dol-w-900 {
    width: 900px;
}
.dol-w-m-150 {
    max-width: 150px;
}

.dol-w-min-content {
    width: min-content;
}

/* BACKGROUND */
.dol-bg-primary {
    background:#6095b2;
}
.dol-bg-white {
    background:#fff; 
}
.dol-bg-gray {
    background:#f4f4f4;
}
.dol-bg-gray-2 {
    background:#ddd;
}
.dol-bg-gray-3 {
    background:#e1e2e1;
}
.dol-bg-black {
    background:#111;
}
.dol-bg-black-2 {
    background:rgba(0,0,0,.1);
}

/* HEIGHT */
.dol-h-1 {
    height: 1px;
}

.dol-h-4 {
    height: 4px;
}

.dol-h-14 {
    height: 14px;
}

.dol-h-20 {
    height: 20px;
}

.dol-h-25 {
    height: 25px;
}

.dol-h-30 {
    height: 30px;
}

.dol-min-h-30 {
    min-height: 30px;
}

.dol-h-35 {
    height: 35px;
}

.dol-h-40 {
    height: 40px;
}

.dol-h-42 {
    height: 42px !important;
}

.dol-h-50 {
    height: 50px !important;
}

.dol-h-55 {
    height: 55px !important;
}

.dol-h-60 {
    height: 60px;
}

.dol-h-65 {
    height: 65px;
}

.dol-h-70 {
    height: 70px;
}

.dol-h-80 {
    height: 80px;
}

.dol-h-85 {

    height: 85px;
}

.dol-h-88 {
    height: 88px;
}

.dol-max-h-50 {
    max-height: 50px;
}

.dol-max-h-95 {
    max-height: 95px;
}

.dol-h-90 {
    height: 90px;
}

.dol-h-100 {
    height: 100px;
}

.dol-h-105 {
    height: 105px;
}

.dol-h-135 {
    height: 135px;
}

.dol-h-148 {
    height: 148px;
}

.dol-h-160 {
    height: 160px;
}

.dol-max-h-160 {
    max-height: 160px;
}

.dol-h-165 {
    height: 165px;
}

.dol-h-180 {
    height: 180px;
}

.dol-min-h-170 {
    min-height: 170px;
}

.dol-h-198 {
    height: 198px;
}

.dol-h-200 {
    height: 200px;
}

.dol-h-210 {
    height: 210px;
}

.dol-h-212 {
    height: 212px;
}

.dol-h-134 {
    height: 134px;
}

.dol-h-140 {
    height: 140px;
}

.dol-max-h-390 {
    max-height: 390px;
}

.dol-h-260 {
    height: 260px;
}

.dol-h-270 {
    height: 270px;
}

.dol-h-295 {
    height: 295px;
}

.dol-h-310 {
    height: 310px;
}

.dol-h-315 {
    height: 315px;
}

.dol-h-345 {
    height: 345px;
}

.dol-h-355 {
    height: 355px;
}

.dol-h-360 {
    height: 360px;
}

.dol-h-390 {
    height: 390px;
}

.dol-h-400 {
    height: 400px;
}

.dol-h-435 {
    height: 435px;
}

.dol-max-h-440 {
    max-height: 440px;
}

.dol-h-465 {
    height: 465px;
}

.dol-h-605 {
    height: 605px;
}

.dol-h-m-100 {
    max-height: 100px;
}
.dol-h-auto {
    height: auto;
}

/* BORDERS */
.dol-b-none {
    border: none !important;
}

.dol-b-1x {
    border: 1px solid;
}

.dol-b-t-1x {
    border-top: 1px solid;
}

.dol-b-b-1x {
    border-bottom: 1px solid;
}

.dol-b-l-1x {
    border-left: 1px solid;
}

.dol-b-r-1x {
    border-right: 1px solid;
}

.dol-b-8 {
    border: 8px solid;
}

.dol-b-t-8 {
    border-top: 8px solid;
}

.dol-b-b-8 {
    border-bottom: 8px solid;
}

.dol-b-l-8 {
    border-left: 8px solid;
}

.dol-b-r-8 {
    border-right: 8px solid;
}

.dol-b-2x {
    border: 2px solid;
}

.dol-b-t-2x {
    border-top: 2px solid;
}

.dol-b-b-2x {
    border-bottom: 2px solid;
}

.dol-b-l-2x {
    border-left: 2px solid;
}

.dol-b-r-2x {
    border-right: 2px solid;
}

.dol-b-10x {
    border: 10px solid;
}

.dol-b-0 {
    border: 0px !important;
}

.dol-b-b-0 {
    border-bottom: 0px !important;
}

.dol-b-t-0 {
    border-top: 0px !important;
}

.dol-b-l-0 {
    border-left: 0px !important;
}

.dol-b-r-0 {
    border-right: 0px !important;
}


.dol-b-3 {
    border: 3px solid !important;
}

.dol-b-b-3 {
    border-bottom: 3px solid !important;
}

.dol-b-t-3 {
    border-top: 3px solid !important;
}

.dol-b-l-3 {
    border-left: 3px solid !important;
}

.dol-b-r-3 {
    border-right: 3px solid !important;
}

.dol-b-t-4 {
    border-top: 4px solid !important;
}

.mw-b-radius-25rem {
    border-radius: .25rem;
}
.mw-b-t-r-radius-25rem {
    border-top-right-radius: .25rem;
}
.mw-b-t-l-radius-25rem {
    border-top-left-radius: .25rem;
}
.mw-b-t-l-radius-5 {
    border-top-left-radius: 5px;
}
.mw-b-t-r-radius-5 {
    border-top-right-radius: 5px;
}
.mw-b-b-r-radius-5 {
    border-bottom-right-radius: 5px;
}
.mw-b-b-l-radius-5 {
    border-bottom-left-radius: 5px;
}
.mw-b-radius-5 {
    border-radius: 5px;
}
.mw-b-radius-10 {
    border-radius: 10px;
}
.mw-b-t-l-radius-10 {
    border-top-left-radius: 10px;
}
.mw-b-t-r-radius-10 {
    border-top-right-radius: 10px;
}
.mw-b-b-r-radius-10 {
    border-bottom-right-radius: 10px;
}
.mw-b-b-l-radius-10 {
    border-bottom-left-radius: 10px;
}
.mw-b-radius-20 {
    border-radius: 20px;
}
.mw-b-radius-30 {
    border-radius: 30px;
}
.mw-b-radius-50 {
    border-radius: 50%;
}

/* COLORS */
.dol-c-primary {
    color:#6095b2;
}
.dol-c-gray {
    color:#999;
}
.dol-c-gray-2 {
    color:#666;
}
.dol-c-gray-3 {
    color:#555;
}
.dol-c-gray-4 {
    color:#444;
}
.dol-c-green {
    color:#61c592;
}
.dol-c-white, .dol-c-white a:link, .dol-c-white a:visited, .dol-c-white a:hover {
    color:#fff !important;
}

.dol-c-blue {
    color: #6095b2;
}

.dol-c-black{
    color: #000
}

.dol-c-red{
    color: #ff0000;
}

.dol-title:before {
    content: "";
    position: absolute;
    width: 5px;
    background: #ccc;
    border-radius: 5px;
    height: 40%;
    left: 0;
    top: 12px;
}

.mw-object-top {
    object-position: top;
}

.mw-object-fit {
    object-fit: cover;
}

.mw-object-contain {
    object-fit: contain;
}

.mw-center {
    margin: 0 auto !important;
    text-align: center !important;
}

.mw-left-auto {
    margin-left: auto !important;
}

.mw-right-auto {
    margin-right: auto !important;
}

.mw-flex {
    display: flex !important;
}

.mw-inline-flex {
    display: inline-flex !important;
}

.mw-flex-justify-left {
    justify-content: left !important;
}

.mw-flex-justify-center {
    justify-content: center;
}

.mw-flex-justify-c-end {
    justify-content: flex-end;
}

.mw-flex-justify-s-between {
    justify-content: space-between;
}

.mw-a-self-center {
    align-self: center !important;
}

.mw-a-items-center {
    align-items: center !important;
}

.mw-a-items-baseline {
    align-items: baseline !important
}

.mw-a-content-center {
    align-content: flex-end !important;
}

.mw-flex-wrap {
    flex-wrap: wrap !important;
}

.mw-flex-grow-1 {
    flex-grow: 1;
}

.mw-block {
    display: block !important;
}

.mw-wrapper {
    position: relative !important;
}

.mw-inherit {
    position: inherit;
}

.mw-bottom-absolute {
    bottom: 0!important;
    position: absolute !important;
}

.mw-top-absolute {
    top: 0!important;
    position: absolute !important;
}

.mw-right-absolute {
    right: 0!important;
    position: absolute !important;
}

.mw-right {
    right: 0 !important;
}

.mw-left-absolute {
    left: 0!important;
    position: absolute !important;
}

.mw-left {
    left: 0 !important;
}

.mw-top {
    top: 0 !important;
}

.mw-bottom {
    bottom: 0 !important;
}

.mw-fit-height {
    height: 100% !important;
}

.mw-fit-width {
    width: 100% !important;
}

.mw-auto-height {
    height: auto;
}

.mw-auto-width {
    width: auto;
}

.mw-pad-0 {
    padding: 0 !important;
}

.mw-pad-5rem {
    padding: .5rem;
}

.mw-pad-0 {
    padding: 0 !important;
}

.mw-pad-l-0 {
    padding-left: 0! important;
}

.mw-pad-t-0 {
    padding-top: 0 !important;
}

.mw-pad-r-0 {
    padding-right: 0!important;
}

.mw-pad-l-5 {
    padding-left: 5px!important
}

.mw-pad-r-5 {
    padding-right: 5px!important
}

.mw-pad-t-5 {
    padding-top: 5px!important
}

.mw-pad-b-5 {
    padding-bottom: 5px!important
}

.mw-pad-5 {
    padding: 5px!important
}

.mw-pad-l-10 {
    padding-left: 10px !important;
}

.mw-pad-r-10 {
    padding-right: 10px !important;
}

.mw-pad-t-10 {
    padding-top: 10px !important;
}

.mw-pad-b-10 {
    padding-bottom: 10px !important;
}

.mw-pad-10 {
    padding: 10px !important;
}

.mw-pad-l-15 {
    padding-left: 15px!important;
}

.mw-pad-r-15 {
    padding-right: 15px!important;
}

.mw-pad-t-15 {
    padding-top: 15px!important
}

.mw-pad-b-15 {
    padding-bottom: 15px!important
}

.mw-pad-15 {
    padding: 15px!important
}

.mw-pad-20 {
    padding: 20px!important
}

.mw-pad-l-20 {
    padding-left: 20px!important;
}

.mw-pad-r-20 {
    padding-right: 20px!important;
}

.mw-pad-t-20 {
    padding-top: 20px!important
}

.mw-pad-b-20 {
    padding-bottom: 20px!important
}

.mw-pad-25 {
    padding: 25px!important
}

.mw-pad-l-25 {
    padding-left: 25px!important;
}

.mw-pad-r-25 {
    padding-right: 25px!important;
}

.mw-pad-t-25 {
    padding-top: 25px!important
}

.mw-pad-b-25 {
    padding-bottom: 25px!important
}

.mw-pad-30 {
    padding: 30px!important
}

.mw-pad-l-30 {
    padding-left: 30px!important;
}

.mw-pad-r-30 {
    padding-right: 30px!important;
}

.mw-pad-t-30 {
    padding-top: 30px!important
}

.mw-pad-b-30 {
    padding-bottom: 30px!important
}

.mw-pad-40 {
    padding: 40px!important
}

.mw-pad-l-40 {
    padding-left: 40px!important;
}

.mw-pad-r-40 {
    padding-right: 40px!important;
}

.mw-pad-t-40 {
    padding-top: 40px!important
}

.mw-pad-b-40 {
    padding-bottom: 40px!important
}

.mw-pad-50 {
    padding: 50px!important
}

.mw-pad-l-50 {
    padding-left: 50px!important;
}

.mw-pad-r-50 {
    padding-right: 50px!important;
}

.mw-pad-t-50 {
    padding-top: 50px!important
}

.mw-pad-b-50 {
    padding-bottom: 50px!important
}

.mw-pad-30 {
    padding: 30px!important
}

.mw-m-0 {
    margin: 0px !important;
}

.mw-m-t-0 {
    margin-top: 0px !important;
}

.mw-m-b-0 {
    margin-bottom: 0px !important;
}

.mw-m-l-0 {
    margin-left: 0px !important;
}

.mw-m-r-0 {
    margin-right: 0px !important;
}

.mw-m-5 {
    margin: 5px !important;
}

.mw-m-t-5 {
    margin-top: 5px !important;
}

.mw-m-b-5 {
    margin-bottom: 5px !important;
}

.mw-m-l-5 {
    margin-left: 5px !important;
}

.mw-m-r-5 {
    margin-right: 5px !important;
}

.mw-m-10 {
    margin: 10px !important;
}

.mw-m-t-10 {
    margin-top: 10px !important;
}

.mw-m-b-10 {
    margin-bottom: 10px !important;
}

.mw-m-l-10 {
    margin-left: 10px !important;
}

.mw-m-r-10 {
    margin-right: 10px !important;
}

.mw-m-15 {
    margin: 15px !important;
}

.mw-m-t-15 {
    margin-top: 15px !important;
}

.mw-m-b-15 {
    margin-bottom: 15px !important;
}

.mw-m-l-15 {
    margin-left: 15px !important;
}

.mw-m-r-15 {
    margin-right: 15px !important;
}

.mw-m-20 {
    margin: 20px !important;
}

.mw-m-t-20 {
    margin-top: 20px !important;
}

.mw-m-b-20 {
    margin-bottom: 20px !important;
}

.mw-m-l-20 {
    margin-left: 20px !important;
}

.mw-m-r-20 {
    margin-right: 20px !important;
}

.mw-m-25 {
    margin: 25px !important;
}

.mw-m-t-25 {
    margin-top: 25px !important;
}

.mw-m-b-25 {
    margin-bottom: 25px !important;
}

.mw-m-l-25 {
    margin-left: 25px !important;
}

.mw-m-r-25 {
    margin-right: 25px !important;
}

.mw-m-30 {
    margin: 30px !important;
}

.mw-m-t-30 {
    margin-top: 30px !important;
}

.mw-m-b-30 {
    margin-bottom: 30px !important;
}

.mw-m-l-30 {
    margin-left: 30px !important;
}

.mw-m-r-30 {
    margin-right: 30px !important;
}

.mw-m-50 {
    margin: 50px !important;
}

.mw-m-t-50 {
    margin-top: 50px !important;
}

.mw-m-b-50 {
    margin-bottom: 50px !important;
}

.mw-m-l-50 {
    margin-left: 50px !important;
}

.mw-m-r-50 {
    margin-right: 50px !important;
}

.dpa-logo {
    width: 40%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dpa-bg-red {
    background: #ff0000 !important;
}

.dpa-bg-white {
    background: #FFFFFF;
}

.dpa-bg-black {
    background: #000000;
}


.dpa-links-footer a, .dpa-links-footer a:hover {
    text-decoration: none;
}

.dpa-btn {
    margin-left: 10px;
    margin-right: 10px;
}